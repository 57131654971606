import { getRating } from '@/helpers/statusIncident.js';
import { supportRequests } from '@/helpers/dummyData';
import TicketItem from '@/components/request/elements/TicketItem/TicketItem.vue';
import { mapActions, mapState } from 'vuex';
import { showError, hasItems } from '@/helpers/globalHelpers';

export default {
	name: 'RequestListTab',
	data() {
		return {
			loading: false,
			searchTimeout: null,
			searchValue: '',
			dialog: false,
			
			statusList: [
				{ text: 'TODOS LOS ESTADOS', value: 'ALL' },
				{ text: 'SOLICITADO', value: 'SOLICITADO' },
				{ text: 'RESUELTO', value: 'RESUELTO' },
				{ text: 'EN ATENCIÓN', value: 'EN ATENCION' },
				{ text: 'CONSULTA', value: 'CONSULTA' },
				{ text: 'CERRADO', value: 'CERRADO' },
				{ text: 'CALIFICADO', value: 'CALIFICADO' },
				{ text: 'PENDIENTE AP', value: 'PENDIENTE AP' },
			],
			attentionTypeList:[
				{ text: 'VIRTUAL', value: 'VIRTUAL' },
				{ text: 'PRESENCIAL', value: 'PRESENCIAL' },
			],
			ticketLimits: [10, 20, 50, 80],
			ticketfilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				num_ticket: null,
				status: null,
				attention_type:null,
				generic: false,
				//q: '',
			},
			supportRequests: supportRequests,
		};
	},

	created() {
		this.getTickets();
		// this.getUsersActive();
		this.getResponsibles();
	},

	methods: {
		// ...mapActions('security', ['getUsersActive']),
		...mapActions('user',['getResponsibles']),

		...mapActions('ticket', ['listTicketForResponsibles', 'cleanTickets']),
		getRating,
		hasItems,

		searchTickets() {
			this.ticketfilters.page = 1;
			this.getTickets();
		},

		async getTickets() {
			this.loading = true;

			if(this.ticketfilters.status === 'ALL'){
				this.ticketfilters.status = null
			}

			const { error } = await this.listTicketForResponsibles(this.ticketfilters);
			if (error) showError(error);
			this.loading = false;
		},
		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.ticketfilters.num_ticket = this.searchValue;
				this.getTickets();
			}, 800);
		},
	},

	beforeDestroy() {
		this.cleanTickets();
	},
	destroyed() {
		clearTimeout(this.timeout);
	},

	components: {
		TicketItem,
	},

	computed: {
		...mapState('ticket', [
			'tickets',
			'ticketsPagination',
			'loadingTicket',
			'filtersTicket',
		]),
		
	},
};
