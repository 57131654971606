import IncidentTab from '@/components/request/elements/IncidentTab/IncidentTab.vue';
import IncidentConfirmTab from '@/components/request/elements/IncidentConfirmTab/IncidentConfirmTab.vue';

export default {
	name: 'RequestList',
	data() {
		return {
			tab: 0,
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Atención de Tickets',
					link: true,
					exact: true,
					disabled: true,
				},
			],
		};
	},

	watch: {
		tab(newTab) {
			this.$nextTick(() => {
				if (newTab === 0 && this.$refs.incidentTab) {
					this.$refs.incidentTab.getTickets();
				} else if (newTab === 1 && this.$refs.incidentConfirmTab) {
					this.$refs.incidentConfirmTab.getTickets();
				}
			});
		},
	},

	created() {},

	methods: {},

	beforeDestroy() {},
	destroyed() {
	},

	components: {
		IncidentTab,
		IncidentConfirmTab,
	},

	computed: {
		hasReturnListPermission() {
			return this.$store.getters['auth/hasActivity'](
				'devoluciones',
				'listaDevoluciones',
				'listarDevoluciones'
			);
		},
	},
};
