import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import { showError } from '@/helpers/globalHelpers';

export default {
	name: 'IncidentConfirmTab',

	data() {
		return {
			loading: false,
			tickets: [],
			ticketFilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: false,
				generic: false,
				num_ticket: null,
				status: 'PENDIENTE AP',
				area: null,
			},
			headers: [
				{ text: 'Número de Ticket', sortable: false, value: 'num_ticket' },
				{
					text: 'Área',
					sortable: false,
					value: 'subtype.classification.area.name',
				},
				{ text: 'Subtipo', sortable: false, value: 'subtype.name' },
				{
					text: 'Responsable',
					sortable: false,
					value: 'responsible_id.fullName',
				},
				{
					text: 'Creador',
					sortable: false,
					value: 'creator_id.fullName',
				},
				{ text: 'Fecha de registro', sortable: false, value: 'created_at' },
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
					align: 'center',
				},
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
		};
	},

	computed: {
		...mapState('auth', ['user']),
	},

	methods: {
		getIncidentStatus,
		...mapActions('ticket', ['listAllTickets']),

		async getTickets() {
			this.loading = true;
			const { ok, response, error } = await this.listAllTickets(
				this.ticketFilters
			);

			if (ok) {
				this.tickets = response.tickets.filter(
					(ticket) =>
						ticket.ticket_user_confirm?.length > 0 &&
						ticket.ticket_user_confirm[ticket.ticket_user_confirm.length - 1]?.user_confirm === this.user.id
				);
			} else {
				showError(error);
			}

			this.loading = false;
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
	},

	created() {
		this.getTickets();
	},

	beforeDestroy() {},
};
