import moment from 'moment';
import { getRating } from '@/helpers/statusIncident.js';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import ReassignUserDialog from '../../elements/ReassignUserDialog';
import { mapState, mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'TicketItem',
	props: {
		value: Boolean,
		tickets: Array,
		loading: Boolean,
	},

	data() {
		return {
			reassignUserDialog: false,
			loadingNotifications: {},
			source: 'request-list',
			item: null,
			headers: [
				{ text: 'Ticket', sortable: false, value: 'num_ticket' },
				{ text: 'Descripción', sortable: false, value: 'description' },
				{ text: 'Tipo de atención', sortable:false, value:'attention_type'},
				{ text: 'Fecha de creación', sortable: false, value: 'created_at' },
				{ text: 'Asignado', sortable: false, value: 'responsible_id.fullName' },
				{ text: 'Creador', sortable: false, value: 'creator_id.fullName' },
				{ text: 'Servicio', sortable: false, value: 'service' },
				{ text: 'Estado', sortable: false, value: 'status' },
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
		};
	},
	computed: {
		hasShowDetailPermission() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'detalleSolicitud',
				'mostrarSolicitud'
			);
		},

		hasDisabledTicketReassign() {
			return this.$store.getters['auth/hasActivity'](
				'solicitudes',
				'listaSolicitudes',
				'reasignarResponsable'
			);
		},

		...mapState('auth', ['user']),
	},

	created() {
		//this.getUsersActive();
	},

	methods: {
		...mapActions('ticket', ['sendTicketCloseNotificationMail']),

		getRating,
		getIncidentStatus,
		changeFilter() {},

		async sendNotification(id) {
			if (!id) return;
			this.$set(this.loadingNotifications, id, true);
			try {
				const { ok, error } = await this.sendTicketCloseNotificationMail(id);
				if (ok) {
					$EventBus.$emit(
						'showSnack',
						'success',
						'Notificación enviada correctamente.'
					);
				} else {
					$EventBus.$emit(
						'showSnack',
						'error',
						error || 'Error al enviar la notificación.'
					);
				}
			} catch (err) {
				$EventBus.$emit(
					'showSnack',
					'error',
					'Error inesperado al enviar la notificación.'
				);
			} finally {
				this.$set(this.loadingNotifications, id, false);
			}
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		reassignUser(item) {
			this.item = Object.assign({}, item);
			this.reassignUserDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},

		getAllTickets() {
			this.$emit('getTickets');
		},

		disableButtonReassignResponsible(item) {
			const statusIsValid =
				item.status === 'SOLICITADO' ||
				item.status === 'CONSULTA' ||
				item.status === 'RESUELTO';

			const userIsAuthorized =
				item.subtype.classification.responsible_id.id === this.user.id ||
				item.subtype.classification.area.responsible_id.id === this.user.id ||
				this.hasDisabledTicketReassign;

			const shouldEnableButton = statusIsValid && userIsAuthorized;

			return !shouldEnableButton;
		},
	},

	components: {
		ReassignUserDialog,
	},
};
